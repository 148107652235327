@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600");
body {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  font-family:
    "Poppins",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.MuiButtonBase-root {
  text-transform: none !important;
}

a {
  color: #5d31ff;
}
